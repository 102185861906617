<template>
  <app-form-view
    app="product"
    model="product"
    api-url="product/product/"
    :title="$t('menu.product')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
    :prepare-data="prepareData"
  >
    <template v-slot="{ view }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="master_product"
            type="select-server"
            :label="$t('fields.master_product')"
            :view="view"
            :binds="{
              apiUrl: 'product/master-product/?active=true'
            }"
            v-model="formData.master_product_id"
            @input="getDataFromMaster"
          >
            <template v-slot:view-value="{ value }">
              {{ value.name }}
            </template>
          </app-input>
          <app-input
            v-if="isSentToApprove"
            name="approveMasterProduct"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_master_product"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            type="text"
            rules="required"
            :label="$t('fields.product_name')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveProductName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_product_name"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="code"
            type="text"
            rules="required"
            :label="$t('fields.product_code')"
            :view="view"
            v-model="formData.code"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveProductCode"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_product_code"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="product_price"
            type="number"
            rules="required"
            :label="$t('fields.product_price')"
            :view="view"
            v-model="formData.product_price"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveProductPrice"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_product_price"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="description"
            type="text"
            :label="$t('fields.product_description')"
            :view="view"
            v-model="formData.description"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveProductDescription"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_product_description"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="whtRate"
            type="number"
            rules="required|between:0,100"
            :label="$t('fields.wht')"
            :view="view"
            v-model="formData.whtRate"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveProductWhtRate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_product_whtRate"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="vat"
            type="switch"
            :label="$t('fields.vat')"
            :view="view"
            v-model="formData.vat"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveProductVat"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_product_vat"
          />
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="app-input--approve-wrapper"
          v-if="formData.vat"
        >
          <app-input
            name="vatRate"
            type="number"
            rules="required|between:0,100"
            :label="$t('fields.vatRate')"
            :view="view"
            v-model="formData.vatRate"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveProductVatRate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_product_vatRate"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="isAuditPeriod"
            type="switch"
            :label="$t('fields.isAuditPeriod')"
            :view="view"
            v-model="formData.is_audit_period"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveProductIsAuditPeriod"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_product_is_audit_period"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'

export default {
  name: 'product-form',
  components: {
    AppInput,
    AppFormView
  },
  data() {
    return {
      masterProductLoading: true,
      masterProductItems: [],
      formData: {
        state: 'draft'
      },
      approveItems: [
        'approve_master_product',
        'approve_product_name',
        'approve_product_description',
        'approve_product_code',
        'approve_product_whtRate',
        'approve_product_price',
        'approve_product_vat',
        'approve_product_is_audit_period',
        'approve_product_vatRate'
      ]
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'productCreate'
    })
  },
  watch: {
    'formData.name': function (value) {
      if (this.$route.name === 'productEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'productEdit',
          text: value,
          to: {
            name: 'productEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {
    prepareData(data) {
      if (
        this.formData.master_product_id !== null &&
        this.formData.master_product_id !== undefined
      ) {
        data.master_product_id = data.master_product_id.id
      }
      return data
    },
    getDataFromMaster() {
      this.params = {
        id: this.formData.master_product_id
      }

      if (
        this.formData.master_product_id !== null &&
        this.formData.master_product_id !== undefined
      ) {
        this.$api({
          method: 'get',
          url:
            'product/master-product/?id=' + this.formData.master_product_id.id,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.$nextTick(() => {
            const master_product_id = this.formData.master_product_id
            this.formData = {
              ...data.results[0],
              master_product_id: master_product_id,
              product_price: data.results[0].master_product_price,
              state: this.formData.state
            }
          })
        })
      }
    }
  }
}
</script>
